import { browser } from '$app/environment';
import { writable } from 'svelte/store';
import type { Writable } from 'svelte/store';
import { safeLocalStorage } from '$lib/utilities/local-storage';

export function persistStore<T>(
  key: string,
  initialValue: T | null = null,
  broadcastToAll = false,
): Pick<Writable<T | null>, 'subscribe' | 'set' | 'update'> {
  let initialStoreValue = initialValue;
  let broadcaster: null | BroadcastChannel;

  if (browser) {
    try {
      if (safeLocalStorage.getItem(key)) {
        initialStoreValue = JSON.parse(safeLocalStorage.getItem(key) ?? '');
      }
    } catch (_err) {
      initialStoreValue = null;
    }
  }

  const { subscribe, set, update } = writable<T | null>(initialStoreValue);

  if (browser && broadcastToAll) {
    try {
      broadcaster = new BroadcastChannel(`persist-store-${key}`);
      broadcaster?.addEventListener('message', (event) => {
        set(event.data);
      });
    } catch (e) {
      console.error('Browser does not support BroadcastChannel');
    }
  }

  return {
    subscribe,
    set: (x: T) => {
      if (browser) {
        if (broadcaster) broadcaster.postMessage(x);
        if (x === null) {
          safeLocalStorage.removeItem(key);
        } else {
          safeLocalStorage.setItem(key, JSON.stringify(x));
        }
      }
      set(x);
    },
    update: (updater: (x: T | null) => T) => {
      if (browser) {
        safeLocalStorage.removeItem(key);
        update((previousValue) => {
          const updatedValue = updater(previousValue);
          safeLocalStorage.setItem(key, JSON.stringify(updatedValue));
          return updatedValue;
        });
      }
    },
  };
}
